// NA.js
import React from 'react';
import DrinkListWrapper from './DrinkListWrapper';

const NA = () => {
  return (
    <div className="content">
      <DrinkListWrapper apiUrl={'/na'}/>
    </div>
  );
};

export default NA;