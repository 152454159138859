// App.js
import React, { useState, useEffect, createContext } from 'react';
import './App.css';
import PrivateRoute from './PrivateRoute';
import Login from './components/Login';
import Featured from './components/Featured.js';
import Classics from './components/Classics.js';
import Specialty from './components/Specialty.js';
import NA from './components/NA.js';
import All from './components/All.js';
import Admin from './components/Admin';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

export const AuthContext = createContext(null)

function App() {
  const [profile, setProfile] = useState(null);
  const [hasUser, setHasUser] = useState(sessionStorage.getItem("access_token") != null);
  
  
  const handleLogin = () => {
    googleLogin();
  };

  const handleLogout = () => {
    googleLogout();
    sessionStorage.removeItem('access_token');
    setHasUser(false);
    setProfile(null);
    return <Navigate to="/"/>
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      sessionStorage.setItem('access_token', codeResponse.access_token);
      setHasUser(true);
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
    () => {
      if (hasUser) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${sessionStorage.getItem("access_token")}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            setProfile(res.data);
          })
          .catch((err) => console.log(err));
      }
    },
    [ hasUser ]
  );


  return (
    <Router>
      <AuthContext.Provider value={{profile, handleLogin, handleLogout}}>
      <div className="App">
        <div className="header-wrapper">
          <header>
            <h1>Josh's Drink List</h1>
          </header>
        </div>
        <main>
          <Routes>
            <Route path='/login' element={<Login />}/>
            <Route path='/admin' element={<Admin />}/>
            <Route path="/admin/:email" element={<Admin />}/>
            <Route element={<PrivateRoute/>}>
              <Route exact path='/' element={<Featured />} />
              <Route path='/featured' element={<Featured />}/>
              <Route path='/classics' element={<Classics />} />
              <Route path='/specialty' element={<Specialty />} />
              <Route path='/na' element={<NA />} />
              <Route path='/all' element={<All />} />
            </Route>
          </Routes>
        </main>
        <footer>
          <h3>joshtn.com</h3>
          {profile && (
            <div>
              <p>Logged in as {profile.name}</p>
              <button className='log-out-button' onClick={handleLogout}>Log out</button>
            </div>
          )}
        </footer>
      </div>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;