// Login.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../App';


const Login = ({destination="/"}) => {  
  const {profile, handleLogin} = useContext(AuthContext);

  return (
    <div className="login">
      {!profile ? 
        <div className="login-prompt">
          <p>{profile}</p>
          <h1>Login</h1>
          <button type="button" className="google-sign-in-button" onClick={handleLogin} >
            Sign in with Google
          </button>
        </div>
        :
        <Navigate to={destination}/>
      }
    </div>
  );
};

export default Login;