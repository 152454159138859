// DrinkListWrapper.js
import React, { useState, useEffect, useContext, memo } from 'react';
import DrinkList from './DrinkList';
import api from '../api/posts';
import { AuthContext } from '../App';
import { Navigate } from 'react-router-dom';

const DrinkListWrapper = memo(({ apiUrl = '/featured' }) => {
  const [drinks, setDrinks] = useState([]);
  const [hasFetchedDrinks, setHasFetchedDrinks] = useState(false);
  const [drinkImages, setDrinkImages] = useState({});
  const [drinkImageErrors, setDrinkImageErrors] = useState({});
  const [apiError, setApiError] = useState(null);
  const [unauthorized, setUnauthorized] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");
  const { profile, handleLogout } = useContext(AuthContext);
  

  useEffect(() => {
    const fetchDrinks = async () => {
      if (hasFetchedDrinks) return;
      setHasFetchedDrinks(true);
  
      try {
        const response = await api.get(apiUrl, {
          params: {
            access_token: sessionStorage.getItem("access_token"),
          },
        });
        setApiError(false);
        setUnauthorized(false);
        setDrinks(response.data);
      } catch (err) {
        console.log(`Error: ${err.message}`);
        if (err.message === "Request failed with status code 403") {
          setUnauthorized(true);
        } else {
          setUnauthorized(false);
        }
        setApiError(true);
      }
    }
    fetchDrinks();
  }, [hasFetchedDrinks, apiUrl]);

  useEffect(() => {
    const fetchDrinkImage = async (drink) => {
      let retries = 5;
      while (retries > 0) {
        try {
          const response = await api.get(apiUrl + '-drinkImage', {
            params: {
              access_token: sessionStorage.getItem("access_token"),
              drink_id: drink.id,
            },
            responseType: 'blob',
          });
          setDrinkImages(prevState => ({
            ...prevState,
            [drink.id]: URL.createObjectURL(response.data)
          }));
          setDrinkImageErrors(prevState => ({
            ...prevState,
            [drink.id]: false
          }));
          break;
        } catch (err) {
          if (err.response && err.response.status === 404) {
            retries = 0;
          } else {
            retries -= 1;
          }
          if (retries === 0) {
            setDrinkImageErrors(prevState => ({
              ...prevState,
              [drink.id]: true
            }));
          }
        }
      }
    };

    hasFetchedDrinks && drinks.forEach(drink => {
      fetchDrinkImage(drink);
    });
  }, [drinks, hasFetchedDrinks, apiUrl]);

  
  const handleAccessRequest = async () => {
    try {
      await api.get('/access-request', {
        params: {
          access_token: sessionStorage.getItem("access_token"),
        },
      });
      setRequestStatus("Access request sent successfully.");
    } catch (err) {
      console.log(`Error: ${err.message}`);
      setRequestStatus("An error occurred while processing your access request. Please email contact@joshtn.com to request access.");
    }
  }

  const logout = () => {
    handleLogout();
    return <Navigate to="/" />;
  }


  return (
    <DrinkList
      drinks={drinks}
      requestStatus={requestStatus}
      apiError={apiError}
      unauthorized={unauthorized}
      profile={profile}
      handleAccessRequest={handleAccessRequest}
      logout={logout}
      drinkImages={drinkImages}
      drinkImageErrors={drinkImageErrors}
    />
  );
});

export default DrinkListWrapper;