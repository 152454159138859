// Admin.js
import React, { useState, useEffect, useContext } from 'react';
import api from '../api/posts';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../App';

const Admin = () => {
  const [apiError, setApiError] = useState(null);
  const [requestStatus, setRequestStatus] = useState("");
  const { profile, handleLogin } = useContext(AuthContext);

  let { email } = useParams();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        await api.get('/admin', {
          params: {
            access_token: sessionStorage.getItem("access_token"),
          },
        });
        setApiError(false);
      } catch (err) {
        console.log(`Error: ${err.message}`);
        setApiError(true);
      }
    }
    fetchContent()
  }, [])

  const handleApprove = async () => {
    try {
      const response = await api.get('/approve', {
        params: {
          access_token: sessionStorage.getItem("access_token"),
          email: email,
        },
      });
      if (response.data === "email already approved") {
        setRequestStatus("Email already approved.");
      } else if (response.data === "email successfully approved") {
        setRequestStatus("Email successfully approved.");
      }
    } catch (err) {
      console.log(`Error: ${err.message}`);
      setRequestStatus("An error occured while approving the email.");
    }
  }

  const reloadPage = () => {
    window.location.reload();
  };


  return (
    <div className='content'>
      {apiError!==null && apiError &&
        <div className='server-offline'>
          <h1>Unauthorized Access</h1>
          <br/>
          <br/>
          {!profile ? 
            <button type="button" className="google-sign-in-button" onClick={handleLogin} >
              Sign in with Google
            </button>
          :
            reloadPage()
          }
        </div>
      }
      {apiError!==null && !apiError && 
        <div className="admin-content">
          {!requestStatus ?
            <span>
            <h1>{email}</h1>
            <button to="/" className="approve-button" onClick={handleApprove} >
              Approve Email
            </button>
            </span>
          :
            <div className="request-status">
              <h2>{requestStatus}</h2>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default Admin;