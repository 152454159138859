// All.js
import React from 'react';
import DrinkListWrapper from './DrinkListWrapper';

const All = () => {
  return (
    <div className="content">
      <DrinkListWrapper apiUrl={'/all'}/>
    </div>
  );
};

export default All;