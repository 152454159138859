// DrinkList.js
import React, { useState, useEffect } from 'react';
import DrinkListItem from './DrinkListItem';
import DrinkListItemUnavailable from './DrinkListItemUnavailable';
import Navbar from './Navbar';

const DrinkList = ({drinks, requestStatus, apiError, unauthorized, profile, handleAccessRequest, logout, drinkImages, drinkImageErrors}) => {
  const [drinksOrder, setDrinksOrder] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    let isSet = false;
    const trySetDrinksOrder = () => {
      if (drinks && search==="" && !isSet) {
        isSet = true;
        const newDrinksOrder = [];
        drinks.forEach((drink) => {
          newDrinksOrder.push([drink, drink.id]);
        });
        newDrinksOrder.sort(
          (first, second) => { return first[1] - second[1] }
        );
        setDrinksOrder(newDrinksOrder);
      }
    }
    trySetDrinksOrder();
  }, [drinks, search])

  
  const getAmt = (ingAmt) => {
    if (!isNaN(parseFloat(ingAmt.split(' ')[0]))) {
      return parseFloat(ingAmt.split(' ')[0]);
    } else if (ingAmt.split(' ')[0] === 'fill') {
      return 4
    } else {
      return 0
    }
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    const currSearch = e.target.value.toLowerCase();
    const newDrinksOrder = [];
    drinks.forEach((drink) => {
      const drinkName = drink.name.toLowerCase();
      const drinkRecipe = drink.recipe.toLowerCase();
      if (drinkName.includes(currSearch)) {
        let idx = drinkName.indexOf(currSearch);
        newDrinksOrder.push([drink, idx]);
      } else if (drinkRecipe.includes("\n\n")) {
        const lines = drinkRecipe.split("\n\n")[1].split("\n");
        let isAdded = false;
        for (let lineIdx=0; lineIdx < lines.length; lineIdx++) {
          const line = lines[lineIdx]
          if (!isAdded && line.includes(currSearch)) {
            let lineAmt = ""
            for (var key in drink.ingredients) {
              if (line.includes(key)) {
                lineAmt = drink.ingredients[key]
              }
            }
            if (lineAmt === "") {
              continue;
            }
            const ingAmt = getAmt(lineAmt)
            let totalAmt = 0
            for (let lineIdx1=0; lineIdx1 < lines.length; lineIdx1++) {
              const lineAmt1 = drink.ingredients[Object.keys(drink.ingredients)[lineIdx1]]
              totalAmt += getAmt(lineAmt1)
            }
            const fract = ingAmt / totalAmt
            newDrinksOrder.push([drink, 101-fract])
            isAdded = true;
          }
        }
        if (!isAdded) {
          const lines = drinkRecipe.split("\n");
          for (let lineIdx=0; lineIdx < lines.length; lineIdx++) {
            const line = lines[lineIdx];
            if (line.includes(currSearch)) {
              newDrinksOrder.push([drink, 102]);
              break;
            }
          }
        }
      }
    });
    newDrinksOrder.sort(
      (first, second) => { return first[1] - second[1] }
    );
    setDrinksOrder(newDrinksOrder);
  }


  return (
    <div className='inner-content'>
      <Navbar handleSearchChange={handleSearchChange}/>
      {apiError===null &&
        <div className="big-loading-wrapper">
          <img className="all-drinks-loading" src="loading.gif" alt="loading drinks"/>
        </div>
      }
      {apiError!==null && apiError && !unauthorized &&
        <div className='server-offline'>
          <h1>The server is currently offline.</h1>
          <h3>Please check back later or email contact@joshtn.com if you believe this is a mistake.</h3>
        </div>
      }
      {apiError!==null && apiError && unauthorized &&
        <div className='unauthorized-access'>
          <h1>Unauthorized Account:</h1>
          <h3>{profile.email}</h3>
          {!requestStatus ?
            <button to="/" className="unauthorized-access-button" onClick={handleAccessRequest} >
              Request Access
            </button>
          :
            <div className="request-status">
              <h3>{requestStatus}</h3>
            </div>
          }
          <br/>
          <button className="unauthorized-access-button" onClick={logout}>
            Log Out
          </button>
        </div>
      }
      {apiError!==null && !apiError && !unauthorized && 
        <div className="drink-list">
          {drinksOrder.map((e) => (e[0].availability === "yes" ?
            <DrinkListItem key={e[0].id} drink={e[0]} drinkImage={drinkImages[e[0].id]} drinkImageError={drinkImageErrors[e[0].id]} />
            :
            <DrinkListItemUnavailable key={e[0].id} drink={e[0]} drinkImage={drinkImages[e[0].id]} drinkImageError={drinkImageErrors[e[0].id]} />
          ))}
        </div>
      }
    </div>
  );
}

export default DrinkList;