// Specialty.js
import React from 'react';
import DrinkListWrapper from './DrinkListWrapper';

const Specialty = () => {
  return (
    <div className="content">
      <DrinkListWrapper apiUrl={'/specialty'}/>
    </div>
  );
};

export default Specialty;