// PrivateRoutes.js
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './App';


const PrivateRoute = () => {
  const {profile} = useContext(AuthContext);;

  return profile ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;