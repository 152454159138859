import React from "react";
import { useNavigate } from 'react-router-dom'

const Navbar = ({ handleSearchChange }) => {
  const navigate = useNavigate();
  return (
    <div className="navbar">
      
      <nav className="navbar-options">
        {/* <button onClick={() => navigate("/featured")}>Download as PDF</button>
        <br /> */}
        <button className="btn third" onClick={() => navigate("/featured")}>Featured</button>
        <button className="btn third" onClick={() => navigate("/classics")}>Classics</button>
        <button className="btn third" onClick={() => navigate("/specialty")}>Specialty</button>
        <button className="btn third" onClick={() => navigate("/na")}>NA</button>
        <button className="btn third" onClick={() => navigate("/all")}>All</button>
      </nav>
      <form>
        <input type="search" className="search" placeholder="Search" onChange={handleSearchChange} />
      </form>
    </div>
  );
};

export default Navbar;