// DrinkListItem.js
import React, { useState, memo } from 'react';

const DrinkListItem = memo(({ drink, drinkImage, drinkImageError }) => {
  const [click, setClick] = useState(0);

  return (
    <div className="drink-list-item"
      onClick={(e) => {
        if (click===0) {
          setClick(1);
        } else {
          setClick(0);
        }
      }
    }>
      <h3>{drink.name}</h3>
      {drinkImage ?
        <img className='square-image' src={drinkImage} alt={drink.name+' picture'}/>
      :
        <div>
        {!drinkImageError && 
          <div className='loading-wrapper'>
            <img className='loading' src="loading.gif" alt={'loading gif'}/>
          </div>
        }
        </div>
      }
      {(click===1 || (drinkImageError && click===0)) &&
        <span>
          <p>{drink.description}</p>
          <div className='show-recipe-button' onClick={(e) => {e.stopPropagation();setClick(2);}}>
            <p>Show Recipe</p>
            <p><i className="down-arrow"></i></p>
          </div>
        </span>
      }
      {click===2 &&
        <span>
          <p>{drink.description}</p>
          <div className="recipe">
            <h3>Recipe</h3>
            {drink.recipe.split("\n").map((line) => {
              if (line === "") {
                return <br/>;
              }
              return <div className="recipe-line"><p>{line}</p></div>;
              })
            }
          </div>
          <div className='show-recipe-button' onClick={(e) => {e.stopPropagation();setClick(1);}}>
            <p><i className="up-arrow"></i></p>
            <p>Hide Recipe</p>
          </div>
        </span>
      }
    </div>
  );
});

export default DrinkListItem;