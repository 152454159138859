// Featured.js
import React from 'react';
import DrinkListWrapper from './DrinkListWrapper';

const Featured = () => {
  return (
    <div className="content">
      <DrinkListWrapper apiUrl={'/featured'}/>
    </div>
  );
};

export default Featured;